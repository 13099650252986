<template>
  <div class="header">
    <div class="headerContainer">

    <div class="icons">
      <i class="bi bi-info-circle" id="infoButtonCircle"></i>
      <i class="bi bi-bar-chart-line" id="statsButtonCircle"></i>
    </div>
 
    <router-link to="/"> 
      <p class="headerTitle">
        Basketball<span class="headerTitleNumber"><strong>5</strong></span>
      </p>
    </router-link>

    <div class="icons">
        <a href="https://www.instagram.com/basketball5_official/" target="_blank" aria-label="Instagram"
          ><i class="bi bi-instagram"></i
        ></a>
        <a href="https://twitter.com/Basketball5game" target="_blank" aria-label="Twitter"
          ><i class="bi bi-twitter"></i
        ></a>
      </div>
  </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
#infoButtonCircle,
#statsButtonCircle {
  /* display: none; */
}

.header {
  background-color: var(--secondary-color);
  color: var(--text-color);
  /* color: #03FCBA;
  background-color: rgb(166, 163, 163) */
  display: flex;
  justify-content: center;
  border-bottom: 1px solid var(--button-color);  
}

.headerContainer { 
  color: var(--bluePop-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between; 
  width: 700px; 
  padding: 15px 0; 
  position: relative;
}

.icons{
  display: flex;
}

.icons i { 
  font-size: 26px;
  margin: 0 15px;
  cursor: pointer;
  color: white;
}

.icons i:hover {
  color: var(--button-color);
}
 
.headerTitle {
  margin: 0;
  /* padding: 5px 0; */
  text-transform: uppercase;
  font-size: 30px;
  /* font-family: Poppins, sans-serif; */
  font-family: "Righteous", cursive;
  color: var(--text-color);
  font-size: 36px; 
  line-height: 36px;

  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
 
}

 

.headerTitleNumber {
  color: var(--button-color);
}

@media screen and (max-width: 750px) {
  .headerContainer {  
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.5rem;
    position: unset;
    padding: 10px;
  }

  .headerTitle {
    font-size: 27px; 
  }

  .icons i {
    font-size: 25px;
    margin: 0px 5px; 
    margin-right: 10px;
  }
}
</style>