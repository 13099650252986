<template>
  <DesktopSideAd />

  <div
    v-if="dailyBasketball5.gameStatus == 'not started'"
    class="gameInfo animate__animated"
  >
    <p class="gameTitle">
      <span style="color: var(--text-color)">Basketball</span
      ><span style="color: var(--button-color)">5</span>
    </p>

    <div class="gameInfoInside">
      <img src="https://i.ibb.co/R6CHX1C/cac.webp" alt="" />

      <div style="text-align: left">
        <p class="gameInfoText">
          Basketball5 is a daily basketball game where you have to pick 5 players from 5 different NBA teams to create your best lineup. 
          <br /><br />
          Each player has a rating, so aim for the highest overall score and compete with your friends daily!
          <br /><br />

           Last roster update: July 23, 2024.

          <br /><br />

          Select your level below: <br />
          <label>
            <input
              type="radio"
              v-model="dailyBasketball5.selectedMode"
              value="normal"
            />
            Normal - All teams</label
          ><br />
          <label>
            <input
              type="radio"
              v-model="dailyBasketball5.selectedMode"
              value="easy"
            />
            Easy - Only top teams </label
          ><br />
          <br />
          Select your timer option below: <br />
          <label>
            <input
              type="radio"
              v-model="dailyBasketball5.selectedTimer"
              value="30s"
            />
            30 seconds</label
          ><br />
          <label>
            <input
              type="radio"
              v-model="dailyBasketball5.selectedTimer"
              value="no"
            />
            No Timer </label
          ><br />
          <br />

          <B5Button :text="'Start Game'" :class="'blue'" @click="startGame()" />
        </p>
      </div>
    </div>
  </div>

  <div v-if="dailyBasketball5.gameStatus != 'not started'">
    <B5LineupVue
      :lineup="dailyBasketball5.lineup"
      :currentRating="dailyBasketball5.currentRating"
    />

    <p
      v-if="dailyBasketball5.gameStatus == 'won'"
      style="font-size: 18px; max-width: 60vw; margin: auto"
    >
      You won. Come back tomorrow to play again.
    </p>
    <p
      v-if="dailyBasketball5.gameStatus == 'lost'"
      style="font-size: 18px; max-width: 60vw; margin: auto"
    >
      You lost. Come back tomorrow to play again.
    </p>

    <div v-if="dailyBasketball5.gameStatus == 'playing'">
      <div class="timerDiv" v-if="dailyBasketball5.selectedTimer == '30s'">
        <div class="timerCircle">
          <span>{{ remainingTime }}</span>
        </div>
      </div>

      <span style="display: flex; justify-content: center">
        <div class="nextTeam" v-if="dailyBasketball5.selectedTeams.length > 0">
          <img :src="dailyBasketball5.selectedTeams[0].logo" alt="" />
          <p>{{ dailyBasketball5.selectedTeams[0].name }}</p>
          <br />
        </div>
      </span>

      <B5SearchField v-model="searchFieldText" @keyup.enter="searchPlayer" />
      <B5Button :text="'Guess'" @click="searchPlayer" />
      <!-- <i class="bi bi-flag-fill flagGiveUp" ></i> -->
      <B5GiveUp @close="endGame('lost')" />

      <div class="positionOptions">
        <span
          v-for="position in positionsOptions"
          :key="position"
          class="positionOption"
        >
          <input type="radio" :value="position" v-model="selectedPosition" />
          <label>{{ position }}</label>
        </span>

        <B5Button
          v-if="positionsOptions"
          @click="insertPlayerInLineup(selectedPosition)"
          :text="'Pick Position'"
          style="margin-top: 10px"
        />
      </div>

      <p style="font-size: 18px">{{ message }}</p>
    </div>

    <teleport to="body">
      <div class="modal" v-if="openPlayersArray">
        <PlayersArray
          @close="openPlayersArray = false"
          :players="multiplePlayersArray"
          @choosePlayer="choosePlayerWithId"
        />
      </div>
    </teleport>
  </div>
  <B5Info v-if="showInfo == true" @close="showInfo = false" :rules="rules" />

  <B5Stats v-if="showStats == true" @close="showStats = false" />

  <LeaderBoardAd />
</template>

<script>
import B5LineupVue from "@/components/B5-Lineup.vue";
import B5SearchField from "@/components/B5-SearchField.vue";
import B5Button from "@/components/B5-Button.vue";
import B5GiveUp from "@/components/B5-GiveUp.vue";
import players from "@/assets/players.json";
import teams from "@/assets/teams.json";
import { ref, onMounted} from "vue";
// import B5Info from "@/components/B5-Info.vue";
import B5Info from "@/components/B5-InfoUpdate.vue";
import B5Stats from "@/components/B5-Stats.vue";
import PlayersArray from "@/components/PlayersArray.vue";
import $ from "jquery";

import LeaderBoardAd from "@/components/ads/LeaderBoardAd.vue";
import DesktopSideAd from "@/components/ads/DesktopSideAd.vue";

export default {
  components: {
    B5LineupVue,
    B5SearchField,
    B5Button,
    B5Info,
    B5GiveUp,
    B5Stats,
    PlayersArray,
    LeaderBoardAd,
    DesktopSideAd,
  },
  setup() {

    onMounted(() => {
      document.title = "Basketball5 Wordle - Play Basketball 5";
    });

    const showInfo = ref(false);
    const showStats = ref(false);

    const openPlayersArray = ref(false);

    const rules = ref({
      title: "Basketball5",
      text: `Basketball5 is a game where you assemble a lineup by selecting five
        players from 5 different NBA teams, presented in a random order.
        <br /><br />
        Each player has a rating so you can try to get the highest possible
        overall for your team and challenge your friends daily. <br /><br />
        You can choose whether you want only top teams or all teams and whether
        you want a 30s timer to finish the lineup or no timer below and start
        the game. <br /><br />
        Last roster update: July 23, 2024`,
    });

    $(document).ready(function () {
      $("#infoButtonCircle").css("display", "unset");
      $("#statsButtonCircle").css("display", "unset");
      $("#infoButtonCircle").click(function () {
        showInfo.value = true;
      });

      $("#statsButtonCircle").click(function () {
        showStats.value = true;
      });
    });

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();
    today = mm + "/" + dd + "/" + yyyy;

    const selectedTeams = ref();
    // teams.sort(() => Math.random() - 0.5).slice(0, 5)

    const lineup = ref([
      { position: "PG", player: null },
      { position: "SG", player: null },
      { position: "C", player: null },
      { position: "PF", player: null },
      { position: "SF", player: null },
    ]);

    const remainingTime = ref(30);

    const storedRemainingTime = window.localStorage.getItem("b5-timer");

    if (!storedRemainingTime) {
      window.localStorage.setItem("b5-timer", remainingTime.value);
    } else {
      remainingTime.value = storedRemainingTime;
    }

    let intervalId = null;

    const timer = () => {
      const storeTimer = () => {
        window.localStorage.setItem(
          "b5-timer",
          JSON.stringify(remainingTime.value)
        );
      };

      intervalId = setInterval(() => {
        remainingTime.value--;

        storeTimer();

        if (remainingTime.value <= 0) {
          clearInterval(intervalId);
          if (dailyBasketball5.value.selectedTeams.length > 0) {
            endGame("lost");
          }

          saveStorage();
        }
      }, 1000);
    };

    // const selectMode = (mode, selectedTimer) => {
    //   if (mode == "Easy") {
    //     let selectedIDs = [2, 5, 10, 13, 14, 24];
    //     dailyBasketball5.value.selectedTeams = teams
    //       .sort(() => Math.random() - 0.5)
    //       .filter((team) => selectedIDs.includes(team.id))
    //       .slice(0, 5);
    //     console.log("easy");
    //   } else if (mode == "Normal") {
    //     let selectedIDs = [
    //       1, 2, 3, 5, 6, 8, 9, 10, 11, 13, 14, 16, 20, 21, 24, 27, 30,
    //     ];
    //     dailyBasketball5.value.selectedTeams = teams
    //       .sort(() => Math.random() - 0.5)
    //       .filter((team) => selectedIDs.includes(team.id))
    //       .slice(0, 5);
    //     console.log("normal");
    //   } else if (mode == "Hard") {
    //     dailyBasketball5.value.selectedTeams = teams
    //       .sort(() => Math.random() - 0.5)
    //       .slice(0, 5);

    //     console.log("hard");
    //   }

    //   dailyBasketball5.value.gameStatus = "playing";

    //   if (selectedTimer == "30s") {
    //     remainingTime.value = 30;
    //     dailyBasketball5.value.selectedTimer = '30s';
    //     timer();
    //   }
    //   saveStorage();

    //   showInfo.value = false;
    // };

    const dailyBasketball5 = ref({
      selectedTeams: selectedTeams.value,
      lineup: lineup.value,
      currentRating: 0,
      gameStatus: "not started",
      selectedTimer: "30s",
      selectedMode: "normal",
    });

    let dataSot = new Date(Date.now());
    let stringDataSot =
      dataSot.getDate() +
      "/" +
      (dataSot.getMonth() + 1) +
      "/" +
      dataSot.getFullYear();

    let lastTimestamp = Number(window.localStorage.getItem("b5-timestamp"));
    let dataKaluar = new Date(lastTimestamp);
    let stringDataKaluar =
      dataKaluar.getDate() +
      "/" +
      (dataKaluar.getMonth() + 1) +
      "/" +
      dataKaluar.getFullYear();

    if (stringDataSot != stringDataKaluar) {
      window.localStorage.removeItem("b5-daily");
      window.localStorage.removeItem("b5-timestamp");
      window.localStorage.removeItem("b5-timer");
    }

    let lastUpdatedTimestamp = Date.now();

    const searchFieldText = ref("");
    const message = ref("");
    const positionsOptions = ref();
    let selectedPlayer;

    const basketball5 = ref({
      stats: {
        totalMatches: 0,
        totalWins: 0,
        currentStreak: 0,
        maxStreak: 0,
        maxRating: 0,
        lowRating: 0,
      },
      playersDistribution: [0, 0, 0, 0, 0, 0],
    });

    const initLocalStorage = () => {
      const storedStats = window.localStorage.getItem("b5-stats");

      if (!storedStats) {
        window.localStorage.setItem(
          "b5-stats",
          JSON.stringify(basketball5.value)
        );
      } else {
        basketball5.value = JSON.parse(storedStats);
      }

      const storedB5Daily = window.localStorage.getItem("b5-daily");

      if (!storedB5Daily) {
        window.localStorage.setItem(
          "b5-daily",
          JSON.stringify(dailyBasketball5.value)
        );
      } else {
        dailyBasketball5.value = JSON.parse(storedB5Daily);
      }

      const storedTimestamp = window.localStorage.getItem("b5-timestamp");

      if (!storedTimestamp) {
        window.localStorage.setItem("b5-timestamp", lastUpdatedTimestamp);
      }
    };

    initLocalStorage();

    if (
      dailyBasketball5.value.selectedTimer == "30s" &&
      dailyBasketball5.value.gameStatus == "playing"
    ) {
      timer();
    }

    const saveStorage = () => {
      window.localStorage.setItem(
        "b5-stats",
        JSON.stringify(basketball5.value)
      );

      window.localStorage.setItem(
        "b5-daily",
        JSON.stringify(dailyBasketball5.value)
      );

      // window.localStorage.setItem("b5-timestamp", Date.now());
    };

    const startGame = () => {
      if (dailyBasketball5.value.selectedMode == "easy") {
        let selectedIDs = [2, 5, 10, 13, 14, 24];
        dailyBasketball5.value.selectedTeams = teams
          .sort(() => Math.random() - 0.5)
          .filter((team) => selectedIDs.includes(team.id))
          .slice(0, 5);
      } else if (dailyBasketball5.value.selectedMode == "normal") {
        dailyBasketball5.value.selectedTeams = teams
          .sort(() => Math.random() - 0.5)
          .slice(0, 5);
      }

      if (dailyBasketball5.value.selectedTimer == "30s") {
        remainingTime.value = 30;
        dailyBasketball5.value.selectedTimer = "30s";
        timer();
      }

      dailyBasketball5.value.gameStatus = "playing";
      saveStorage();
    };

    const multiplePlayersArray = ref();

    const selectedPosition = ref();
    const searchPlayer = () => {
      positionsOptions.value = null;
      message.value = null;

      multiplePlayersArray.value = players.filter(
        (p) =>
          p.clubID == dailyBasketball5.value.selectedTeams[0].id &&
          (noSpaceRegex(searchFieldText.value) == noSpaceRegex(p.lastName) ||
            noSpaceRegex(searchFieldText.value) ==
              noSpaceRegex(p.firstName + p.lastName))
      );

      if (multiplePlayersArray.value.length > 1) {
        openPlayersArray.value = true;
      }

      if (multiplePlayersArray.value.length == 1) {
        choosePlayerWithId(multiplePlayersArray.value[0].id);
      }

      if (multiplePlayersArray.value.length == 0) {
        message.value = "Can't find " + searchFieldText.value + " in this team";
      }
    };

    const choosePlayerWithId = (id) => {
      selectedPlayer = players.find((player) => player.id == id);

      const possiblePositions = selectedPlayer.positions.filter((p) =>
        dailyBasketball5.value.lineup.some(
          (selectedPlayer) =>
            selectedPlayer.position === p && selectedPlayer.player === null
        )
      );

      if (possiblePositions.length > 1) {
        positionsOptions.value = possiblePositions;
        selectedPosition.value = possiblePositions[0];
        // insertPlayerInLineup(possiblePositions[0]);
      } else if (possiblePositions.length == 1) {
        insertPlayerInLineup(possiblePositions[0]);
      } else {
        message.value =
          "There's no place for " +
          selectedPlayer.firstName +
          " " +
          selectedPlayer.lastName;
      }

      openPlayersArray.value = false;
    };

    const insertPlayerInLineup = (position) => {
      selectedPlayer.logo = teams.find(
        (team) => team.id == selectedPlayer.clubID
      ).logo;

      dailyBasketball5.value.lineup.find((l) => l.position == position).player =
        selectedPlayer;

      let filteredPlayers = dailyBasketball5.value.lineup.filter(
        (l) => l.player
      );
      dailyBasketball5.value.currentRating = Math.round(
        filteredPlayers
          .map((p) => p.player.ovr)
          .reduce((sum, ovr) => sum + ovr, 0) / 5
      );

      dailyBasketball5.value.selectedTeams.shift();

      message.value =
        selectedPlayer.firstName +
        " " +
        selectedPlayer.lastName +
        " has been added";
      searchFieldText.value = null;
      positionsOptions.value = null;

      if (dailyBasketball5.value.selectedTeams.length == 0) {
        endGame("win");
      }

      saveStorage();
    };

    const noSpaceRegex = (word) => {
      return word.toLowerCase().replace(/\s/g, "");
    };

    const endGame = (result) => {
      if (result == "win") {
        basketball5.value.stats.totalWins++;
        basketball5.value.stats.currentStreak++;

        if (
          basketball5.value.stats.currentStreak >
          basketball5.value.stats.maxStreak
        ) {
          basketball5.value.stats.maxStreak =
            basketball5.value.stats.currentStreak;
        }
        dailyBasketball5.value.gameStatus = "won";

        checkMaxRating();
      } else if (result == "lost") {
        basketball5.value.stats.currentStreak = 0;
        dailyBasketball5.value.gameStatus = "lost";
      }

      basketball5.value.playersDistribution[
        dailyBasketball5.value.lineup.filter((position) => position.player)
          .length
      ]++;

      basketball5.value.stats.totalMatches++;

      clearInterval(intervalId);

      setTimeout(() => {
        showStats.value = true;
      }, 1000);

      saveStorage();
    };

    const checkMaxRating = () => {
      if (
        dailyBasketball5.value.currentRating > basketball5.value.stats.maxRating
      ) {
        basketball5.value.stats.maxRating =
          dailyBasketball5.value.currentRating;
      }

      if (
        dailyBasketball5.value.currentRating <
          basketball5.value.stats.lowRating ||
        basketball5.value.stats.lowRating == 0
      ) {
        basketball5.value.stats.lowRating =
          dailyBasketball5.value.currentRating;
      }
    };

    return {
      searchFieldText,
      searchPlayer,
      dailyBasketball5,
      message,
      positionsOptions,
      insertPlayerInLineup,
      endGame,
      basketball5,
      showInfo,
      showStats,
      remainingTime,

      teams,
      selectedPosition,
      openPlayersArray,
      multiplePlayersArray,
      choosePlayerWithId,
      startGame,
      rules,
    };
  },
};
</script>

<style scoped>
.gameInfo {
  color: white;
  margin: 25px auto;
  width: 700px;
  max-width: 90vw;
  background-color: var(--field-color);
  border: 1px solid var(--button-color);
  border-radius: 20px;
  /* font-family: monospace; */
  padding: 20px 0;
}

.gameInfoInside {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.gameInfoInside img {
  height: 250px;
  margin-right: 10px;
  min-height: 250px;
  min-width: 272px;
}

.gameInfoText {
  font-size: 14px;
}

.gameInfoTitle {
  font-size: 34px;
  font-weight: 700;
}

.gameTitle {
  font-size: 36px;
  text-transform: uppercase;
  margin: 30px 0;
  font-weight: 400;
}

.gameInfo .gameTitle {
  margin: unset;
}

.nextTeam {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  padding: 0px 10px;
  background-color: var(--secondary-color-opacity);
  /* background-color: var(--button-color);
  color: var(--secondary-color); */
  margin-bottom: 20px;
  /* color: var(--text-color) */
  font-weight: 400;
  font-size: 20px;
  /* border: 2px solid var(--secondary-color) */
}

.nextTeam img {
  height: 45px;
  /* max-width: 30px; */
  margin-right: 15px;
}

.positionOptions {
  margin-bottom: 15px;
}

.flagGiveUp {
  padding: 5px 10px;
  border: 2px solid var(--button-color);
  border-radius: 10px;
  cursor: pointer;
  margin: 0 5px;
  outline: none;
}

.flagGiveUp:hover {
  background-color: rgb(87, 76, 55);
}

.timerDiv {
  position: absolute;
  width: 600px;
  max-width: 90%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.timerCircle {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid;
  border-color: var(--secondary-color);
  border-color: var(--button-color);
  background-color: var(--field-color);
  text-align: center;
  line-height: 50px;
  font-size: 24px;
  color: var(--button-color);
}

@media screen and (max-width: 900px) {
  .nextTeam {
    /* white-space: nowrap; */
    font-size: 15px;
    padding: 10px;
    max-width: 45vw;
  }

  .nextTeam img {
    margin-right: 5px;
  }

  .nextTeam p {
    margin: 0;
    /* font-size: 17px; */
  }

  .gameInfoInside {
    display: unset;
    /* flex-direction: column; */
    padding: unset;
  }

  .gameInfoInside img {
    height: unset;
    width: 150px;
    float: left;
    margin-left: 15px;
    min-width: 165px;
    min-height: 150px;
  }

  .gameInfoInside p {
    margin: 0 20px;
  }

  .gameInfoTitle {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .gameTitle {
    font-size: 26px;
    margin: 20px 0;
  }

  .gameInfo .gameTitle {
    margin-bottom: 20px;
    font-size: 24px;
  }
}
</style>