import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import 'bootstrap-icons/font/bootstrap-icons.css';

/* eslint-disable */
// Ensure the Google Publisher Tag (googletag) is loaded asynchronously in the HTML
createApp(App).use(router).mount('#app');

// Add route change listener to refresh ads after navigation
router.afterEach(() => {
  // Delay to ensure googletag is available
  setTimeout(() => {
    if (window.googletag && googletag.pubads) {
      googletag.pubads().refresh();
    }
  }, 1000); // Add a delay to ensure the ads have time to load
});
